import React, {
  useState,
  useCallback,
  useMemo,
  Suspense,
  lazy,
  useEffect,
} from "react";
import { Box } from "@mui/system";
import axios from "axios";
import Button from "@mui/material/Button";
import debounce from "lodash.debounce";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKeyboard } from "@fortawesome/free-solid-svg-icons";

import logo from "../assets/images/logo.png";

const MapBox = lazy(() => import("../components/MapBox"));
const MyLocation = lazy(() => import("../components/MyLocation"));
const ReRoll = lazy(() => import("../components/ReRoll"));

export default function App() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//t1.daumcdn.net/kas/static/ba.min.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [restaurant, setRestaurant] = useState(null);
  const [address, setAddress] = useState("");
  const [isLocationSet, setIsLocationSet] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const fetchRestaurantsInRadius = useCallback(async (coords) => {
    if (coords.lat && coords.lng) {
      try {
        const response = await axios.get(
          "https://dapi.kakao.com/v2/local/search/category.json",
          {
            headers: {
              Authorization: `KakaoAK 454158023268f9ea8eedd86b73fb6f01`,
            },
            params: {
              category_group_code: "FD6",
              x: coords.lng,
              y: coords.lat,
              radius: 450,
            },
          }
        );

        const restaurants = response.data.documents;
        if (restaurants.length > 0) {
          const randomIndex = Math.floor(Math.random() * restaurants.length);
          setRestaurant(restaurants[randomIndex]);
        } else {
          setRestaurant(null);
          setOpenDialog(true);
        }
      } catch (error) {
        console.error("Error fetching restaurant data:", error);
      }
    }
  }, []);

  const debouncedFetchRestaurants = useMemo(
    () => debounce(fetchRestaurantsInRadius, 200),
    [fetchRestaurantsInRadius]
  );

  const handleAddressSelected = useCallback(
    (newCoordinates, newAddress) => {
      setCoordinates(newCoordinates);
      setAddress(newAddress);
      debouncedFetchRestaurants(newCoordinates);
      setIsLocationSet(true);
    },
    [debouncedFetchRestaurants]
  );

  const resetLocation = useCallback(() => {
    setCoordinates({ lat: null, lng: null });
    setRestaurant(null);
    setAddress("");
    setIsLocationSet(false);
    setOpenDialog(false);
  }, []);

  return (
    <div className="FullPage">
      <Box sx={AppHeaderTitle}>
        <img
          src={logo}
          alt="오점뭐 로고"
          style={{ width: "40px", marginRight: "10px" }}
        />
        <span style={{ fontSize: "40px", fontWeight: "bold" }}>오점뭐</span>
        <span style={{ fontSize: "15px" }}>(오늘 점심 뭐먹지)</span>
      </Box>

      {!isLocationSet && (
        <Box sx={AppText}>
          <FontAwesomeIcon
            icon={faKeyboard}
            size="lg"
            style={{ color: "#3498db", marginRight: "8px" }}
          />
          <span style={{ fontSize: "15px", fontWeight: "bold" }}>
            주소 입력 후 식당 추천받기
          </span>
        </Box>
      )}

      <Box sx={LocationCheck}>
        <Suspense fallback={<div>Loading...</div>}>
          <MyLocation
            setCoordinates={setCoordinates}
            coordinates={coordinates}
            onAddressSelected={handleAddressSelected}
            address={address}
            isLocationSet={isLocationSet}
          />
        </Suspense>
      </Box>

      {isLocationSet && (
        <>
          <Box sx={MapBoxer}>
            <Suspense fallback={<div>Loading...</div>}>
              <MapBox coordinates={coordinates} restaurant={restaurant} />
            </Suspense>
          </Box>
          <Box style={{ textAlign: "center" }}>
            <Box sx={AddressDisplay}>
              <Button onClick={resetLocation} style={resetButtonStyle}>
                위치 재설정
              </Button>
            </Box>
          </Box>

          <Box sx={ReRollButton}>
            <Suspense fallback={<div>Loading...</div>}>
              <ReRoll onReRoll={() => debouncedFetchRestaurants(coordinates)} />
            </Suspense>
          </Box>
        </>
      )}

      {/* 광고 삽입 위치 */}
      <Box sx={{ textAlign: "center", marginTop: "20px" }}>
        <ins
          className="kakao_ad_area"
          style={{ display: "block", margin: "0 auto", maxWidth: "320px" }}
          data-ad-unit="DAN-Uz3xnI4J9f4t9y38"
          data-ad-width="320"
          data-ad-height="50"
        ></ins>
      </Box>

      <Box sx={AppFooter}>
        <span
          style={{ fontSize: "16px", paddingLeft: "180px", fontWeight: "bold" }}
        >
          토2 스토2 - #1 오점뭐
        </span>
        <br /> <br />
        <span
          style={{ fontSize: "15px", paddingLeft: "185px", fontWeight: "bold" }}
        >
          광고 문의 및 장애 신고
        </span>
        <br />
        <span style={{ fontSize: "15px", paddingLeft: "130px" }}>
          E-mail: vic0820@naver.com
        </span>
      </Box>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"식당 없음"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            해당 주소 근방에 추천해줄 식당이 없습니다. 주소를 다시 입력해주세요.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetLocation} style={dialogButtonStyle}>
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const AddressDisplay = {
  marginTop: "10px",
  verticalAlign: "middle",
  display: "inline-block",
};

const resetButtonStyle = {
  width: "340px",
  height: "40px",
  backgroundColor: "#2ecc71",
  color: "white",
  borderRadius: "5px",
  fontSize: "16px",
  fontWeight: "bold",
  cursor: "pointer",
  transition: "background-color 0.3s ease",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textShadow: "1px 1px 2px black", // 검은색 테두리 효과
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1), 0 7px 20px rgba(0, 0, 0, 0.15)",
  border: "2px solid #27ae60",
};

const dialogButtonStyle = {
  backgroundColor: "#3498db",
  color: "white",
  borderRadius: "5px",
  fontSize: "16px",
  border: "none",
  cursor: "pointer",
  transition: "background-color 0.3s ease",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const AppHeaderTitle = {
  height: "60px",
  paddingTop: "20px",
  marginY: "10px",
  textAlign: "center",
  paddingRight: "60px",
};

const AppText = {
  height: "20px",
  paddingTop: "5px",
  marginY: "5px",
  textAlign: "center",
  paddingRight: "130px",
};

const MapBoxer = {
  textAlign: "center",
};

const LocationCheck = {
  textAlign: "center",
  fontWeight: "bold",
};

const ReRollButton = {
  textAlign: "center",
  marginTop: "3px",
};

const AppFooter = {
  height: "60px",
  paddingTop: "5px",
  marginTop: "30px",
  textAlign: "center",
};
